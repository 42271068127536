import React from 'react'

import Layout from '../../components/Layout'

export default class ProjectsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        This is a list of projects. 
      </Layout>
    )
  }
}
